<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="modal-card-title">View Logs of {{ fulfilment.Id }}</span>
        <button class="delete" aria-label="close" v-on:click="Close"></button>
      </header>
      <section class="modal-card-body">
        <!-- Content ... -->
        <table class="table" v-if="fulfilment.Logs && fulfilment.Logs.length > 0">
          <thead>
            <tr>
              <th>CreatedAt</th>
              <th>Type</th>
              <th>Notes</th>
              <th>Status</th>
              <th>Requester</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(l, index) in fulfilment.Logs" :key="index">
              <td>{{l.CreatedAt}}</td>
              <td>
                <span class="tag is-info">{{l.TypeAsString}}</span>
              </td>
              <td style="word-break:break-word">
                <span>{{l.Notes}}</span>
                <div v-if="l.Data">
                  <i class="is-size-6">{{l.Data}}</i>
                </div>
              </td>
              <td>
                <span
                  class="tag is-success"
                  v-if="l.StatusAsString =='Success'"
                >{{l.StatusAsString}}</span>
                <span
                  class="tag is-danger"
                  v-else-if="l.StatusAsString =='Failed'"
                >{{l.StatusAsString}}</span>
                <span class="tag is-warning" v-else>{{l.StatusAsString}}</span>
              </td>
              <td>
                <span v-if="l.Requester">{{l.Requester}}</span>
                <span v-else>Not applicable</span>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else>No logs found for this fulfilment</p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "fulfilmentLogDialog",
  props: {
    fulfilment: Object
  },
  methods: {
    Close: function() {
      this.$emit("close");
    }
  }
};
</script>
